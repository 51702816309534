import React, { FC, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite"

import styles from "./index.module.scss"
import DailyVolume from "../../../../assets/icons/homePage/DailyVolume"
import ExpOfWork from "../../../../assets/icons/homePage/ExpOfWork"
import TradingPairs from "../../../../assets/icons/homePage/TradingPairs"
import SecurityRating from "../../../../assets/icons/homePage/SecurityRating"
import { useMst } from "../../../../models/Root"
import { routes } from "../../../../constants/routing"
import InternalLink from "../../../InternalLink"
import { getVolumes } from "../../../../services/HomeService";

const USP: FC = () => {
	const {
		global: { isAuthenticated },
	} = useMst()

	const [volume, setVolume] = useState<number>(0)

	useEffect(() => {
		 getCurrentVolumes()
	}, [])

	const getCurrentVolumes = async (): Promise<void> => {
		try {
			const data = await getVolumes()
			setVolume(data.trading_volume)
		} catch (e) {
			console.error(e)
		}
	}

	const volumeToString = useMemo(() => {
		if (!volume) {
			return ""
		}
		const suffixes = ["", "K", "mln", "bln", "trn"];
		const str = `${volume.toFixed(0)}`;
		const number = str.slice(0, (str.length - 1) % 3 + 1)
		const index = Math.floor((str.length / 3))
		return `${number}${number ? "+" : ""} ${suffixes[index] || suffixes[0]}`
	}, [volume])

	return (
		<section className={styles.usp}>
			<h2 className="visually-hidden">Over 400 000 users trust us</h2>

			<div className={styles.usp__container}>
				<div className={`${styles.usp__item} ${styles.usp__item___main}`}>
					<div className={styles.usp__itemContentWrapper}>
						<p className={styles.usp__itemHeadline}>
							<span>More than 500 000</span> users trust us
						</p>

						<p className={styles.usp__itemContent}>
							Our exchange is built on trust and security, our customers recognize this. Why not
							join them?
						</p>
					</div>

					{isAuthenticated ? (
						<InternalLink className={styles.usp__itemButton} to={routes.trade.root}>
							Trade
						</InternalLink>
					) : (
						<InternalLink className={styles.usp__itemButton} to={routes.register.root}>
							Get Started
						</InternalLink>
					)}
				</div>

				<div className={styles.usp__item}>
					<div className={styles.usp__itemContentWrapper}>
						<p className={styles.usp__itemHeadline}>{volumeToString}</p>

						<p className={styles.usp__itemContent}>Daily volume</p>
					</div>

					<DailyVolume />
				</div>

				<div className={styles.usp__item}>
					<div className={styles.usp__itemContentWrapper}>
						<p className={styles.usp__itemHeadline}>9 years</p>

						<p className={styles.usp__itemContent}>Experience of work in crypto exchange</p>
					</div>

					<ExpOfWork />
				</div>

				<div className={styles.usp__item}>
					<div className={styles.usp__itemContentWrapper}>
						<p className={styles.usp__itemHeadline}>200+</p>

						<p className={styles.usp__itemContent}>Trading pairs on the platform</p>
					</div>

					<TradingPairs />
				</div>

				<div className={styles.usp__item}>
					<div className={styles.usp__itemContentWrapper}>
						<p className={styles.usp__itemHeadline}>14 positions</p>

						<p className={styles.usp__itemContent}>In the exchange security rating</p>
					</div>

					<SecurityRating />
				</div>
			</div>
		</section>
	)
}

export default observer(USP)
