import React, { FC } from "react"
import { observer } from "mobx-react-lite"

import { routes } from "constants/routing"
import InternalLink from "components/InternalLink"
import AboutUsBannerBackground from "../../../AboutUsBannerBackground";
import styles from "./index.module.scss"

const Banner: FC = () => (
	<section className={styles.banner}>
		<AboutUsBannerBackground />
		<div className={styles.banner__description_btn_wrapper}>
			<p className={styles.banner__description}>BTC-Alpha Your Secure Crypto Trading Partner</p>

			<InternalLink to={routes.support.request} className={styles.banner__button}>
				Contact us
			</InternalLink>
		</div>
	</section>
)

export default observer(Banner)
