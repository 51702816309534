import React, { FC } from "react"

import ArrowRight from "assets/icons/homePage/ArrowRight"
import ProTerminal from "assets/images/HomePage/ProTerminal.webp"
import LiteVersion from "assets/images/HomePage/LiteVersion.webp"
import { routes } from "constants/routing"
import InternalLink from "components/InternalLink"
import styles from "./index.module.scss"

const Supports: FC = () => (
	<section className={styles.supports}>
		<h2 className="visually-hidden">For beginners and professionals</h2>

		<div className={styles.supports__container}>
			<p className={styles.supports__headline}>For beginners and professionals</p>

			<p className={styles.supports__headlineContent}>
				We have considered our solutions to support every stage of your personal growth. Our
				platform caters to both expert investors and total novices, no request it to complex or too
				easy.
			</p>
		</div>

		<div className={`${styles.supports__container} ${styles.supports__container___content}`}>
			<div className={styles.supports__card}>
				<div className={styles.supports__cardContentWrapper}>
					<h3 className={styles.supports__cardHeadline}>Professional Trading Terminal</h3>

					<p className={styles.supports__cardContent}>
						Our ‘Pro’ Trading Terminal provides all of the tools that a seasoned trader requires.
					</p>

					<InternalLink className={styles.supports__cardButton} to={routes.trade.root}>
						<span>Try it</span>

						<ArrowRight />
					</InternalLink>
				</div>

				<img className={styles.supports__cardImg} src={ProTerminal} alt="pro terminal" />
			</div>

			<div className={styles.supports__card}>
				<div className={styles.supports__cardContentWrapper}>
					<h3 className={styles.supports__cardHeadline}>Lite Version (soon)</h3>

					<p className={styles.supports__cardContent}>
						Our ‘Lite’ version allows you to get acquainted with the Crypto world, without all of
						the complexities.
					</p>

					<button type="button" disabled className={styles.supports__cardButton}>
						<span>Soon</span>

						{/*<ArrowRight />*/}
					</button>
				</div>

				<img className={styles.supports__cardImg} src={LiteVersion} alt="lite terminal" />
			</div>
		</div>
	</section>
)

export default Supports
