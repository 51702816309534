import { defineMessages } from "react-intl"

export default defineMessages({
	page_title: {
		id: "app.components.aboutUs.page_title",
		defaultMessage: "About Us | BTC-Alpha",
	},
	page_description: {
		id: "app.components.aboutUs.page_description",
		defaultMessage:
			"Discover BTC-Alpha, a leading cryptocurrency exchange offering secure and reliable trading services. Learn about our mission, values, and team dedicated to providing top-notch support and innovative solutions in the crypto space. Join us and experience the future of digital finance today.",
	},
})
