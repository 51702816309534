import React, { FC, useState } from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"
// eslint-disable-next-line import/no-extraneous-dependencies
import Spline from "@splinetool/react-spline"

// @ts-ignore
import splineCoube from "assets/cubic.spline"
// import BannerSlider from "./Slider"
import Visa from "assets/icons/homePage/Visa"
import Coinmarketcap from "assets/icons/homePage/Coinmarketcap"
import CoinGecko from "assets/icons/homePage/CoinGecko"
import { routes } from "constants/routing"
import useLocaleNavigate from "hooks/useLocaleNavigate"
import { useMst } from "models/Root"
import { FORMAT_NUMBER_OPTIONS_USD } from "constants/format"
import { STARS_STR } from "constants/wallet"
import InternalLink from "components/InternalLink"
import styles from "./index.module.scss"

const Banner: FC = () => {
	const {
		account: { totalBalance },
		global: { isAuthenticated },
		finance: { isBalancesVisible },
	} = useMst()
	const { formatNumber } = useIntl()
	const [email, setEmail] = useState<string>("")
	const localeNavigate = useLocaleNavigate()

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value)
	}

	const handleInputKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === "Enter" && email) {
			localeNavigate(routes.register.getRegisterWithEmail(email))
		}
	}

	return (
		<section className={styles.banner}>
			<h2 className="visually-hidden">Make your future better. Invest in crypto</h2>

			{/*<div className={styles.banner__bigCircle} />*/}
			{/*<div className={styles.banner__smallCircle} />*/}

			<div className={styles.banner__container}>
				<div className={styles.banner__content}>
					<p className={styles.banner__headline}>Make your future better. Invest in crypto</p>

					{isAuthenticated ? (
						<div>
							<div className={styles.banner__estimatedBalance}>
								<p className={styles.banner__estimatedBalanceHeadline}>Your Estimated Balance:</p>
								<p className={styles.banner__estimatedBalanceContent}>
									{isBalancesVisible
										? formatNumber(totalBalance?.USDT ?? 0, FORMAT_NUMBER_OPTIONS_USD)
										: STARS_STR}{" "}
									USDT
								</p>
							</div>

							<div className={styles.banner__buttonsWrapper}>
								<InternalLink
									className={styles.banner__depositButton}
									to={routes.profile.createDeposit}
								>
									Deposit
								</InternalLink>

								<InternalLink className={styles.banner__tradeButton} to={routes.trade.root}>
									Trade
								</InternalLink>
							</div>
						</div>
					) : (
						<>
							<p className={styles.banner__text}>
								Creating your account with BTC Alpha has never been easier and if you have any
								questions our support is available 24/7 to guide you.
							</p>

							<div className={styles.banner__inputWrapper}>
								<input
									className={styles.banner__input}
									type="text"
									value={email}
									onChange={handleInputChange}
									onKeyDown={handleInputKeyDown}
									placeholder="Enter your email"
								/>

								<InternalLink
									className={styles.banner__signUpButton}
									to={routes.register.getRegisterWithEmail(email)}
								>
									Sign up
								</InternalLink>
							</div>
						</>
					)}

					<ul className={styles.banner__partnersList}>
						<li className={styles.banner__partnersItem}>
							<Visa />
						</li>

						<li className={styles.banner__partnersItem}>
							<Coinmarketcap />
						</li>

						<li className={styles.banner__partnersItem}>
							<CoinGecko />
						</li>
					</ul>
				</div>

				{/*<div className={styles.banner__slider}>*/}
				{/*	<BannerSlider />*/}
				{/*</div>*/}

				<div className={styles.banner__illustration}>
					<Spline scene="https://prod.spline.design/ZtAWGF5OJMDCanKY/scene.splinecode" />
				</div>
			</div>
		</section>
	)
}

export default observer(Banner)
