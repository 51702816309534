import React, { FC } from "react"
import { observer } from "mobx-react-lite"

import walletImage from "assets/images/AboutUs/wallet.webp"
import walletMobileImage from "assets/images/AboutUs/walletMobile.webp"
import styles from "./index.module.scss"

const Wallet: FC = () => (
	<section className={styles.wallet}>
		<h3 className="visually-hidden">Your key to a secure Crypto Journey</h3>

		<div className={styles.wallet__container}>
			<div className={styles.wallet__banner}>
				<img
					className={styles.wallet__image}
					src={walletImage}
					width="365.85"
					height="509"
					alt="wallet"
				/>
				<img
					className={styles.wallet__imageMobile}
					src={walletMobileImage}
					width="327"
					height="435"
					alt="wallet"
				/>
			</div>

			<div className={styles.wallet__content}>
				<h4 className={styles.wallet__title}>Prioritizing Your Funds Safety</h4>
				<p className={styles.wallet__description}>
					We emphasize security to protect your assets, implementing advanced measures to provide a
					trustworthy trading environment and peace of mind.
				</p>
			</div>
		</div>
	</section>
)

export default observer(Wallet)
