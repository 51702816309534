import React, { FC } from "react"

import LowFeesImage from "assets/images/HomePage/LowFeesImage.webp"
import styles from "./index.module.scss"
import InternalLink from "../../../../InternalLink"
import { routes } from "../../../../../constants/routing"

const LowFees: FC = () => (
	<div className={styles.lowFees}>
		<div className={styles.lowFees__content}>
			<div className={styles.lowFees__contentWrapper}>
				<h3 className={styles.lowFees__headline}>Low Fees</h3>

				<p className={styles.lowFees__contentText}>
					Our fees are different for all of our users - our VIP users can appreciate some of the
					best fees the market has to offer. Please find our trading fees here
				</p>
			</div>

			<InternalLink to={routes.tradingFees}>
				<button className={styles.lowFees__button} type="button">
					Learn more
				</button>
			</InternalLink>
		</div>

		<img
			className={styles.lowFees__svg}
			src={LowFeesImage}
			width="280"
			height="280"
			alt="Low fees"
		/>
	</div>
)

export default LowFees
