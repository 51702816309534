import React, { FC } from "react"
import { observer } from "mobx-react-lite"

import { routes } from "constants/routing"
import InternalLink from "components/InternalLink"
import styles from "./index.module.scss"

const Question: FC = () => (
	<section className={styles.question}>
		<div className={styles.question__container}>
			<div className={styles.question__content}>
				<h3 className={styles.question__headline}>Do you have any question?</h3>

				<p className={styles.question__description}>
					Text us and we’ll give you answers for any questions :)
				</p>
			</div>

			<InternalLink className={styles.question__link} to={routes.support.request}>
				Ask a question
			</InternalLink>
		</div>
	</section>
)

export default observer(Question)
