import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"

import HelmetRegister from "components/NewUI/Helmets/Register"
import PinCode from "components/NewUI/Auth/PinCode"
import AuthLayout from "layouts/AuthLayout"
import RegPersonalForm from "components/NewUI/Auth/RegPersonalForm"
import RegJuridicalForm from "components/NewUI/Auth/RegJuridicalForm"
import RegWelcome from "components/NewUI/Auth/RegWelcome"
import RegForm from "components/NewUI/Auth/RegForm"
import { useMst } from "models/Root"
import LootBox, { ELootBoxColor } from "../../components/LootBox"

const Register: React.FC = () => {
	const {
		register: {
			step,
			pinCode,
			setPinCode,
			pinCodeTimeout,
			pinCodeTriesLeft,
			backToFirstStep,
			resendPinCodeToEmail,
			pinCodeGeneratedAt,
			sendPinCodeToEmail,
			resetRegisterPinCodeState,
			resetState,
			errors: { pinCode: pinCodeError },
		},
	} = useMst()
	const [opened, setOpened] = React.useState(false)

	useEffect(() => () => resetState(), [])

	return (
		<AuthLayout>
			<HelmetRegister />

			{step === "" ? <RegForm /> : null}

			{step === "pincode" ? (
				<PinCode
					pinCodeTimeout={pinCodeTimeout}
					pinCodeGeneratedAt={pinCodeGeneratedAt}
					triesLeft={pinCodeTriesLeft}
					onChange={setPinCode}
					value={pinCode}
					backAction={backToFirstStep}
					resendAction={resendPinCodeToEmail}
					sendAction={sendPinCodeToEmail}
					resetAction={resetRegisterPinCodeState}
					isError={pinCodeError}
				/>
			) : null}

			{step === "personal_info" ? <RegPersonalForm /> : null}

			{step === "juridical_info" ? <RegJuridicalForm /> : null}

			{step === "finish" ? <RegWelcome /> : null}
		</AuthLayout>
	)
}

export default observer(Register)
