import React, { FC } from "react"

import Security360Image from "assets/images/HomePage/Security360Image.webp"
import styles from "./index.module.scss"

const Security360: FC = () => (
	<div className={styles.security360}>
		<div className={styles.security360__content}>
			<div className={styles.security360__contentWrapper}>
				<h3 className={styles.security360__headline}>360 Security</h3>

				<p className={styles.security360__contentText}>
					Enjoy access to our top of the line security features both for your account and our
					systems. Our system has proven countless times its resilience and integrity. We are
					audited and proven by many third party partners.
				</p>
			</div>

			{/*<button className={styles.security360__button} type="button">*/}
			{/*	Learn more*/}
			{/*</button>*/}
		</div>

		<img
			className={styles.security360__svg}
			src={Security360Image}
			width="280"
			height="280"
			alt="360 Security"
		/>
	</div>
)

export default Security360
