import React from "react"
import { observer } from "mobx-react-lite"

import Check from "assets/icons/auth/Check"
import Cross from "assets/icons/auth/Cross"
import styles from "./PasswordRules.module.scss"

const PasswordRules: React.FC<{
	isPassErrorLength: boolean
	isPassSpecialChar?: boolean
	isPassNumber?: boolean
	isPassLowercase: boolean
	isPassUppercase: boolean
}> = ({ isPassErrorLength, isPassSpecialChar, isPassLowercase, isPassUppercase, isPassNumber }) => (
	<div className={styles.passwordRules}>
		<div className={styles.passwordRules__item}>
			{isPassErrorLength ? <Cross /> : <Check />}

			<span>8 Characters</span>
		</div>

		<div className={styles.passwordRules__item}>
			{isPassUppercase ? <Cross /> : <Check />}

			<span>Uppercase letter</span>
		</div>

		{isPassSpecialChar !== undefined ? (
			<div className={styles.passwordRules__item}>
				{isPassSpecialChar ? <Cross /> : <Check />}

				<span>Special symbol</span>
			</div>
		) : null}

		{isPassNumber !== undefined ? (
			<div className={styles.passwordRules__item}>
				{isPassNumber ? <Cross /> : <Check />}

				<span>One digit</span>
			</div>
		) : null}

		<div className={styles.passwordRules__item}>
			{isPassLowercase ? <Cross /> : <Check />}

			<span>Lowercase letter</span>
		</div>
	</div>
)

export default observer(PasswordRules)
