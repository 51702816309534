import React, { FC } from "react"

import AppImage from "assets/images/HomePage/iphone.webp"
import iPhoneMobile from "assets/images/HomePage/iPhoneMobile.webp"
import Donut from "assets/images/HomePage/donut.webp"
import CheckMark from "assets/icons/homePage/CheckMark"
import TriangleRedIcon from "assets/icons/header/TriangleRedIcon"
import styles from "./index.module.scss"
import ExternalLink from "../../../ExternalLink"
import config from "../../../../helpers/config"
import AppleBtnImg from "../../../../assets/images/common/app-store.svg"
import GoogleBtnImg from "../../../../assets/images/common/google-play.svg"

const MobileApp: FC = () => (
	<section className={styles.mobileApp}>
		<h2 className="visually-hidden">Awesome Mobile App</h2>

		<div className={styles.mobileApp__container}>
			<div className={styles.mobileApp__containerWrapper}>
				<div className={styles.mobileApp__images}>
					<img className={styles.mobileApp__phoneImage} src={AppImage} alt="Mobile app" />
					<img className={styles.mobileApp__donutImage} src={Donut} alt="Donut" />
					<img className={styles.mobileApp__mobileImage} src={iPhoneMobile} alt="Mobile Phone" />
				</div>

				<div className={styles.mobileApp__contentWrapper}>
					<p className={styles.mobileApp__headline}>Awesome Mobile App</p>

					<p className={styles.mobileApp__content}>
						BTC-Alpha unlocks the potential of neo banking and trading platform.
					</p>

					<ul className={styles.mobileApp__list}>
						<li className={styles.mobileApp__item}>
							<CheckMark />

							<span>24/7 on the go Access</span>
						</li>

						<li className={styles.mobileApp__item}>
							<CheckMark />

							<span>Lite + Pro Versions</span>
						</li>

						<li className={styles.mobileApp__item}>
							<CheckMark />

							<span>Secure</span>
						</li>
					</ul>

					<div className={styles.mobileApp__buttonWrapper}>
						<ExternalLink className={styles.mobileApp__button} to={config.mobileAppAppStore}>
							<img src={AppleBtnImg} alt="logo" width="140" height="50" />
						</ExternalLink>

						{/*<ExternalLink*/}
						{/*	className={`${styles.mobileApp__button} ${styles.mobileApp__button___disabled}`}*/}
						{/*	to={config.mobileAppGooglePlay}*/}
						{/*>*/}
						{/*	<img src={GoogleBtnImg} alt="logo" width="140" height="50" />*/}
						{/*</ExternalLink>*/}
					</div>
				</div>
			</div>

			<div className={styles.mobileApp__circle} />

			<div className={styles.mobileApp__triangle}>
				<TriangleRedIcon />

				<div className={styles.mobileApp__circleBlue} />
			</div>
		</div>
	</section>
)

export default MobileApp
