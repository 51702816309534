import React, { FC } from "react"

import XIcon from "assets/icons/homePage/XIcon"
import Telegram from "assets/icons/homePage/Telegram"
import RightControl from "assets/icons/homePage/RightControl"
import Linkedin from "assets/icons/homePage/Linkedin"
import ExternalLink from "components/ExternalLink"
import styles from "./index.module.scss"

const Communities: FC = () => (
	<section className={styles.communities}>
		<div className={styles.communities__container}>
			<h2 className={styles.communities__headline}>Join our community</h2>

			<p className={styles.communities__content}>
				Join our community to discover the next alpha project and connect with fellow members!
			</p>
		</div>

		<div className={styles.communities__container}>
			<ul className={styles.communities__list}>
				<li className={styles.communities__item}>
					<ExternalLink to="https://twitter.com/btcalpha" className={styles.communities__link}>
						<XIcon />

						<span className={styles.communities__linkContent}>
							<span className={styles.communities__linkName}>X</span>

							<span className={styles.communities__linkCount}>5 000+ followers</span>
						</span>

						<span className={styles.communities__linkArrow}>
							<RightControl />
						</span>
					</ExternalLink>
				</li>

				<li className={styles.communities__item}>
					<ExternalLink
						className={styles.communities__link}
						to="https://www.linkedin.com/company/btcalpha/"
					>
						<Linkedin />

						<span className={styles.communities__linkContent}>
							<span className={styles.communities__linkName}>LinkedIn</span>

							<span className={styles.communities__linkCount}>300+ followers</span>
						</span>

						<span className={styles.communities__linkArrow}>
							<RightControl />
						</span>
					</ExternalLink>
				</li>

				<li className={styles.communities__item}>
					<ExternalLink className={styles.communities__link} to="https://t.me/btcalpha">
						<Telegram />

						<span className={styles.communities__linkContent}>
							<span className={styles.communities__linkName}>Telegram</span>

							<span className={styles.communities__linkCount}>1 500+ followers</span>
						</span>

						<span className={styles.communities__linkArrow}>
							<RightControl />
						</span>
					</ExternalLink>
				</li>
			</ul>
		</div>
	</section>
)

export default Communities
