import React, { FC } from "react"

import CryptoCard from "assets/images/HomePage/CryptoCard.webp"
import styles from "./index.module.scss"

const Partners: FC = () => (
	<section className={styles.cryptoCards}>
		<h2 className="visually-hidden">Partners</h2>

		<div className={styles.cryptoCards__container}>
			<div className={styles.cryptoCards__circle} />

			<div className={styles.cryptoCards__gradient}>
				<p className={styles.cryptoCards__headline}>The Revolutionary Crypto Card</p>

				<p className={styles.cryptoCards__content}>
					Get the one that matches your vibe. Some cards are only available on subscription plans.
					Fees may apply.
				</p>

				<button className={styles.cryptoCards__button} disabled type="button">
					Coming soon in Q3
				</button>

				<img
					className={styles.cryptoCards__img}
					src={CryptoCard}
					alt="The Revolutionary Crypto Card"
				/>
			</div>
		</div>
	</section>
)

export default Partners
