import React, { FC, useEffect, useRef, useState } from "react"
import { observer } from "mobx-react-lite"
import Slider from "react-slick"

import historyImg2016 from "assets/images/AboutUs/history/h2016.webp"
import historyImg2017 from "assets/images/AboutUs/history/h2017.webp"
import historyImg2018 from "assets/images/AboutUs/history/h2018.webp"
import historyImg2019 from "assets/images/AboutUs/history/h2019.webp"
import historyImg2020 from "assets/images/AboutUs/history/h2020.webp"
import historyImg2021 from "assets/images/AboutUs/history/h2021.webp"
import historyImg2022 from "assets/images/AboutUs/history/h2022.webp"
import historyImg2023 from "assets/images/AboutUs/history/h2023.webp"
import roadmapImg1 from "assets/images/AboutUs/roadmap/roadmap1.webp"
import roadmapImg2 from "assets/images/AboutUs/roadmap/roadmap2.webp"
import roadmapImg3 from "assets/images/AboutUs/roadmap/roadmap3.webp"
import roadmapImg4 from "assets/images/AboutUs/roadmap/roadmap4.webp"
import roadmapImg5 from "assets/images/AboutUs/roadmap/roadmap5.webp"
import CheckQ from "assets/icons/aboutUs/CheckQ"
import ProgressQ from "assets/icons/aboutUs/ProgressQ"
import EmptyQ from "assets/icons/aboutUs/EmptyQ"
import styles from "./index.module.scss"
import { NextArrow, PrevArrow } from "./Arrows"

const History: FC = () => {
	const [nav1, setNav1] = useState(null)
	const [nav2, setNav2] = useState(null)
	let sliderRef1: any = useRef(null)
	let sliderRef2: any = useRef(null)
	let sliderRef3: any = useRef(null)

	const settings1 = {
		centerMode: true,
		slidesToShow: 5,
		focusOnSelect: true,
		infinite: true,
		slidesToScroll: 1,
		speed: 500,
		autoplay: false,
		autoplaySpeed: 10000,
		pauseOnHover: true,
		initialSlide: 7,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
		asNavFor: nav2,
		swipeToSlide: false,
		responsive: [
			{
				breakpoint: 1300,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					infinite: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerPadding: "105px",
				},
			},
		],
	}

	const settings2 = {
		slidesToShow: 1,
		fade: true,
		slidesToScroll: 1,
		speed: 500,
		pauseOnHover: true,
		swipeToSlide: false,
		arrows: false,
		initialSlide: 7,
		customHeight: true,
		asNavFor: nav1,
	}

	const settings3 = {
		dots: true,
		slidesToShow: 1,
		autoplay: true,
		autoplaySpeed: 5000,
		slidesToScroll: 1,
		speed: 500,
		pauseOnHover: true,
		swipeToSlide: false,
		arrows: false,
		initialSlide: 5,
	}

	useEffect(() => {
		setNav1(sliderRef1)
		setNav2(sliderRef2)
	}, [])

	const arr = [
		{
			year: 2016,
			title: "The foundation of BTC-Alpha: The Rise to Innovation",
			description:
				"On November 1, 2016, Vitalii Bodnar opened the doors to the world of cryptocurrencies by launching BTC-Alpha. This was the first step towards creating the platform.",
			image: historyImg2016,
		},
		{
			year: 2017,
			title: "Path to Success: Growth and Optimisation",
			description: "BTC-Alpha's first year saw significant growth with platform optimization, a rapidly expanding user base, new features, and a year-end analysis to set future goals.",
			image: historyImg2017,
		},
		{
			year: 2018,
			title: "Challenges and Triumphs: Refusing to Sell Out",
			description:
				"In 2018, despite a €20 million offer from the Korean fund NX, Vitaly Bodnar chose independence, focusing on growth, adding new cryptocurrencies, and planning for innovation.",
			image: historyImg2018,
		},
		{
			year: 2019,
			title: "Strengthening the Position: Continued Growth",
			description:
				"BTC-Alpha continues its confident development by enhancing platform performance, expanding its user base and trading opportunities, and refining strategies to achieve even greater success.",
			image: historyImg2019,
		},
		{
			year: 2020,
			title: "Recognition: Ranking in the Top 3 Crypto Exchanges",
			description: "In 2020, BTC-Alpha gained international recognition as a Top 3 crypto exchange, bolstered its reputation, improved functionality, attracted new users, and planned for future success.",
			image: historyImg2020,
		},
		{
			year: 2021,
			title: "Challenges and Answers: Defending and Burning Tokens",
			description:
				"In 2021, BTC-Alpha overcame challenges by enhancing ALP Coin’s value, attracting investors, preparing for a U.S. license, and proving its security by repelling a hacker attack.",
			image: historyImg2021,
		},
		{
			year: 2022,
			title: "Ethics and Responsibility: Changes in Operations",
			description:
				"In response to global events, BTC-Alpha ceased operations with Russian clients, focused on supporting existing users, and strengthened international ties while preparing for new challenges.",
			image: historyImg2022,
		},
		{
			year: 2023,
			title: "Platform for the Future: Continuous Development",
			description: "In 2023, BTC-Alpha advanced with major updates, new user engagement programs, enhanced trading features, and concluded by planning for 2024.",
			image: historyImg2023,
		},
	]

	return (
		<section className={styles.history}>
			<h3 className={styles.history__headline}>History</h3>

			<div className={styles.history__sliderContentNavWrapper}>
				<div className={styles.history__sliderContent}>
					{/*@ts-ignore*/}
					<Slider
						ref={slider => {
							sliderRef2 = slider
						}}
						className={styles.history__sliderContentWrapper}
						{...settings2}
					>
						{arr.map(item => (
							<div key={item.year}>
								<div className={styles.history__sliderItemWrapper}>
									<div className={styles.history__sliderItemContent}>
										<p className={styles.history__sliderItemHeadline}>{item.title}</p>

										<p className={styles.history__sliderItemDescription}>{item.description}</p>
									</div>

									<img
										className={styles.history__sliderItemImage}
										width={309}
										height={324}
										src={item.image}
										alt={`${item.year}`}
									/>
								</div>
							</div>
						))}
					</Slider>
				</div>

				<div className={styles.history__sliderContainer}>
					<div className={styles.history__sliderNav}>
						<div className={styles.history__line}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="2000"
								height="2"
								viewBox="0 0 2000 2"
								fill="none"
							>
								<path d="M0.5 1H2000" stroke="#7C59FF" strokeWidth="2" strokeDasharray="10 10" />
							</svg>
						</div>

						{/*@ts-ignore*/}
						<Slider
							ref={slider => {
								sliderRef1 = slider
							}}
							className={styles.history__slider}
							{...settings1}
						>
							{arr.map(item => (
								<div key={item.year}>
									<div className={styles.history__sliderNavItemWrapper}>
										<div className={styles.history__sliderNavItem}>
											<p className={styles.history__sliderNavItemText}>{item.year}</p>
										</div>
									</div>
								</div>
							))}
						</Slider>
					</div>
				</div>
			</div>

			<div className={styles.history__content}>
				<div className={styles.history__itemWrapper}>
					<div className={styles.history__itemContent}>
						<p className={styles.history__itemHeadline}>Check Our Roadmap</p>

						<p className={styles.history__itemDescription}>
							Our Roadmap is always open to feedback and suggestions from our valued customers.
						</p>

						<ul className={styles.history__qList}>
							<li className={styles.history__qItem}>
								<div className={styles.history__qItemIcon}>
									<CheckQ />
								</div>
								<p className={styles.history__qText}>2024 Q1 - Exchange updates, new design and features</p>
							</li>

							<li className={styles.history__qItem}>
								<div className={styles.history__qItemIcon}>
									<div className={styles.history__checkLine} />
									<ProgressQ />
								</div>
								<p className={`${styles.history__qText} ${styles.history__qText___progress}`}>
									2024 Q2 - Ios app release, Android app development
								</p>
							</li>

							<li className={styles.history__qItem}>
								<div className={styles.history__qItemIcon}>
									<div className={styles.history__emptyLine} />
									<EmptyQ />
								</div>
								<p className={styles.history__qText}>
									2024 Q3 - Android app release
								</p>
							</li>

							<li className={styles.history__qItem}>
								<div className={styles.history__qItemIcon}>
									<div className={styles.history__emptyLine} />
									<EmptyQ />
								</div>
								<p className={styles.history__qText}>
									2024 Q4 - Preparing for 2025
								</p>
							</li>
						</ul>
					</div>

					<div className={styles.history__sliderRoadmapWrapper}>
						<Slider
							ref={slider => {
								sliderRef3 = slider
							}}
							className={styles.history__sliderRoadmap}
							{...settings3}
						>
							<img
								className={styles.history__itemImage}
								width={360}
								height={426}
								src={roadmapImg1}
								alt='roadmap_image1'
							/>
							<img
								className={styles.history__itemImage}
								width={360}
								height={426}
								src={roadmapImg2}
								alt='roadmap_image2'
							/>
							<img
								className={styles.history__itemImage}
								width={360}
								height={426}
								src={roadmapImg3}
								alt='roadmap_image3'
							/>
							<img
								className={styles.history__itemImage}
								width={360}
								height={426}
								src={roadmapImg4}
								alt='roadmap_image4'
							/>
							<img
								className={styles.history__itemImage}
								width={360}
								height={426}
								src={roadmapImg5}
								alt='roadmap_image5'
							/>
						</Slider>
					</div>
				</div>
			</div>
		</section>
	)
}

export default observer(History)