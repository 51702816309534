import { formatHistoryOrder, formatHistoryOrders, isOrderDone } from "helpers/history"
import { getParent, Instance, flow, destroy, types as t, cast, detach } from "mobx-state-tree"
import { transformDate } from "utils/dayjs"
import HistoryService from "services/HistoryService"
import { IGetOrdersParams, IGetPositionsParams, IGetProfileTradesParams } from "types/history"
import { OrderSideEnum, OrderStatusEnum, OrderTypeEnum } from "types/orders"
import { HistoryModelNamesEnum } from "types/models"
import errorHandler from "utils/errorHandler"
import { ISubAccount } from "types/subAccounts"
import { ORDER_TYPE_FLIPPED } from "constants/orders"
import { Withdraw } from "./Withdrawal"
import { Deposit } from "./Deposit"
import { IRootStore } from "./Root"

const HistoryOrderTrade = t.model({
	amount1: t.maybeNull(t.number),
	amount2: t.maybeNull(t.number),
	date: t.number,
	fee_amount: t.maybeNull(t.number),
	fee_currency_id: t.maybeNull(t.string),
	fee_rate: t.maybeNull(t.number),
	id: t.number,
	pair_id: t.string,
	price: t.number,
	type: t.maybeNull(t.number),
})
export type IHistoryOrderTrade = Instance<typeof HistoryOrderTrade>

const HistoryOrderPair = t.model({
	amount_precision: t.number,
	id: t.string,
	is_enabled: t.boolean,
	label: t.string,
	maximum_order_size: t.number,
	minimum_order_size: t.number,
	minimum_order_value: t.number,
	price_precision: t.number,
	symbol: t.string,
})
export type IHistoryOrderPair = Instance<typeof HistoryOrderPair>

const PositionPair = t.model({
	base_currency_code: t.string,
	quote_currency_code: t.string,
	symbol: t.string,
	price_precision: t.number,
	amount_precision: t.number,
})
export type IPositionPair = Instance<typeof PositionPair>

const Position = t.model(HistoryModelNamesEnum.POSITION, {
	base_amount: t.number,
	base_price: t.number,
	closed_at: t.maybeNull(t.string),
	direction: t.number,
	wallet_type: t.number,
	opened_at: t.string,
	pair: PositionPair,
	quote_amount: t.number,
})
export type IPosition = Instance<typeof Position>

const TradeHistory = t.model({
	amount2: t.number,
	date: t.number,
	id: t.number,
	price: t.number,
	order_id: t.number,
	side: t.number,
	pair_id: t.string,
	amount1: t.number,
	fee_amount: t.number,
	fee_rate: t.number,
	type: t.number,
	wallet_type: t.number,
	order_qta: t.number,
	fee_currency_id: t.string,
	order_status: t.string,
	tradeFeeType: t.maybeNull(t.number),
})
export type ITradeHistory = Instance<typeof TradeHistory>

const HistoryOrder = t
	.model(HistoryModelNamesEnum.HISTORY_ORDER, {
		amount: t.maybeNull(t.number),
		amount_cancelled: t.maybeNull(t.number),
		amount_filled: t.maybeNull(t.number),
		amount_unfilled: t.maybeNull(t.number),
		amount_original: t.maybeNull(t.number),
		filled_percent: t.number,
		date: t.number,
		direction: t.maybeNull(t.string),
		fee_filled: t.maybeNull(t.number),
		id: t.number,
		key: t.string,
		symbol: t.string,
		price: t.maybeNull(t.number),
		pair: t.maybeNull(HistoryOrderPair),
		price_avg: t.maybeNull(t.number),
		status: t.string, // OrderStatusEnum
		type: t.string, // OrderTypeEnum
		side: t.string, // OrderSideEnum
		value_filled: t.maybeNull(t.number),
		stop_price: t.maybeNull(t.number),
		stop_operator: t.maybeNull(t.number),
		open_at: t.maybeNull(t.number),
		done_at: t.maybeNull(t.number),
		updated_at: t.maybeNull(t.number),
		wallet_type: t.maybeNull(t.number),
		side_effect: t.maybeNull(t.number),
		quote_amount: t.maybeNull(t.number),
		order_filled_value: t.maybeNull(t.number),
		order_total_value: t.maybeNull(t.number),
		trades: t.optional(t.array(HistoryOrderTrade), []),
	})
	.views(self => ({
		get totalValue() {
			return (self.amount ?? 0) * (self.price ?? 0) || self.value_filled || 0
		},
	}))
	.volatile(() => ({
		isCancelLoading: false,
		isTradesLoading: false,
	}))
	.actions(self => ({
		setIsCancelLoading(nextIsLoading: boolean) {
			self.isCancelLoading = nextIsLoading
		},
		destroy() {
			if (self) {
				// prettier-ignore
				(getParent(self, 2) as IHistory)?.removeOpenOrder(self as IOrder)
			}
		},
		loadTrades: flow(function* () {
			try {
				self.isTradesLoading = true
				const data = yield HistoryService.getOrder(self.id)
				if (data && Array.isArray(data.trades)) {
					self.trades = cast(data.trades)
				}
			} catch (err) {
				errorHandler(err)
			} finally {
				self.isTradesLoading = false
			}
		}),
	}))

// ts bullshit
export type IOrder = Instance<typeof HistoryOrder>
export interface IHistoryOrder extends IOrder {
	[key: string]: any
	account?: ISubAccount
}

export const History = t
	.model({
		openedOrders: t.optional(t.array(HistoryOrder), []),
		filterTypeOpenedOrders: t.optional(t.string, "LIMIT_MARKET"),
		filterTypeOrderHistory: t.optional(t.string, "LIMIT_MARKET"),
		filterTypeTradeHistory: t.optional(t.string, "LIMIT_MARKET"),
		filterOpenedOrdersCount: t.optional(t.number, 0),
		triggerOrders: t.optional(t.array(HistoryOrder), []),
		isOpenedOrdersLoading: t.optional(t.boolean, false),
		closedOrders: t.optional(t.array(HistoryOrder), []),
		tradeHistory: t.optional(t.array(TradeHistory), []),
		isClosedOrdersLoading: t.optional(t.boolean, false),
		isTradeHistoryLoading: t.optional(t.boolean, false),
		closedOrdersCount: t.optional(t.number, 0),
		filteringSymbol: t.optional(t.string, ""),
		positions: t.optional(t.array(Position), []),
		isPositionsLoading: t.optional(t.boolean, false),
		deposits: t.optional(t.array(Deposit), []),
		depositsCount: t.optional(t.number, 0),
		isDepositsLoading: t.optional(t.boolean, false),
		withdraws: t.optional(t.array(Withdraw), []),
		withdrawsCount: t.optional(t.number, 0),
		isWithdrawsLoading: t.optional(t.boolean, false),
		currentOpenTab: t.optional(t.string, "OpenOrdersTab"),
		currentOpenOrderHistoryOrderID: t.optional(t.string, ""),
	})
	.actions(self => ({
		removeOpenOrder(item: IOrder) {
			destroy(item)
		},
		setFilterOpenedOrdersCount(nextSymbol: number) {
			self.filterOpenedOrdersCount = nextSymbol
		},
		setCurrentOpenTab(nextSymbol: string) {
			self.currentOpenTab = nextSymbol
		},
		setCurrentOpenOrderHistoryOrderID(nextSymbol: string) {
			self.currentOpenOrderHistoryOrderID = nextSymbol
		},
	}))
	.views(self => ({
		get openedOrdersSellKeys() {
			return self.openedOrders
				.filter(o => o.side.toUpperCase() === OrderSideEnum.SELL)
				.map(order => order.key)
		},
		get openedOrdersBuyKeys() {
			return self.openedOrders
				.filter(o => o.side.toUpperCase() === OrderSideEnum.BUY)
				.map(order => order.key)
		},
		get openedOrdersPairLabels() {
			return Array.from(
				new Set([...self.openedOrders.map(order => order.symbol.replace("_", "/"))]),
			)
		},
		get openedOrdersList() {
			let filterFunction

			if (self.filterTypeOpenedOrders === "LIMIT_MARKET") {
				filterFunction = (order: any) => order.type === "LIMIT" || order.type === "MARKET"
			} else if (self.filterTypeOpenedOrders === "STOP_LIMIT") {
				filterFunction = (order: any) => order.type === "STOP_ORDER" || order.type === "STOP_LIMIT"
			} else {
				return []
			}

			const filteredOrders = self.openedOrders.filter(filterFunction)

			return filteredOrders.map(order => ({
				name: order.symbol.replace("_", "/"),
				direction: order.side,
				orderType: order.type,
				orderValue: order.totalValue,
				orderPrice: order.price,
				orderQty: order.amount,
				orderStatus: order.status,
				filledQty: order.amount_filled,
				unFilledQty: order.amount_unfilled,
				orderTime: transformDate(order.date).format("MM/DD/YYYY HH:mm:ss"),
				orderId: order.id,
				action: "Cancel",
			}))
		},
		get positionsList() {
			console.log(self.positions)

			// base_amount: t.number,
			// base_price: t.number,
			// closed_at: t.maybeNull(t.string),
			// direction: t.number,
			// wallet_type: t.number,
			// opened_at: t.string,
			// pair: PositionPair,
			// quote_amount: t.number,

			return self.positions.map(position => ({
				marginName: position.direction,
				marginDirection: position.direction,
				basePrice: position.direction,
				marginAmount: position.direction,
				indexPrice: position.direction,
				liquidationPrice: position.direction,
				pAndL: position.direction,
				pAndLPercent: position.direction,
				date: position.direction,
			}))
		},
		get openedOrdersCount() {
			return self.openedOrders.length
		},
		get closedOrdersList() {
			let filterFunction

			if (self.filterTypeOrderHistory === "LIMIT_MARKET") {
				filterFunction = (order: any) => order.type === "LIMIT" || order.type === "MARKET"
			} else if (self.filterTypeOrderHistory === "STOP_LIMIT") {
				filterFunction = (order: any) => order.type === "STOP_ORDER" || order.type === "STOP_LIMIT"
			} else {
				return []
			}

			const filteredOrders = self.closedOrders.filter(filterFunction)

			return filteredOrders.map(order => ({
				name: order.symbol.replace("_", "/"),
				direction: order.side,
				orderType: order.type,
				orderValue: order.totalValue,
				avgFilledPrice: order.price_avg,
				filledQty: order.amount_filled,
				orderPrice: order.price,
				orderQty: order.amount,
				orderStatus: order.status,
				orderTime: transformDate(order.date).format("MM/DD/YYYY HH:mm:ss"),
				orderId: order.id,
				trades: order.trades,
				details: "Details",
				walletType: order.wallet_type,
			}))
		},
		get tradeHistoryList() {
			let filterFunction

			if (self.filterTypeTradeHistory === "LIMIT_MARKET") {
				filterFunction = (trade: any) =>
					ORDER_TYPE_FLIPPED[trade.type] === "LIMIT" || ORDER_TYPE_FLIPPED[trade.type] === "MARKET"
			} else if (self.filterTypeTradeHistory === "STOP_LIMIT") {
				filterFunction = (trade: any) =>
					ORDER_TYPE_FLIPPED[trade.type] === "STOP_ORDER" || trade.type === "STOP_LIMIT"
			} else {
				return []
			}

			const filteredTrade = self.tradeHistory.filter(filterFunction)

			return filteredTrade.map(trade => ({
				name: trade.pair_id.replace("_", "/"),
				filledPrice: trade.price,
				filledValue: trade.amount2,
				filledQty: trade.amount1,
				id: trade.id,
				orderId: trade.order_id,
				date: transformDate(trade.date).format("MM/DD/YYYY HH:mm:ss"),
				feeCurrency: trade.fee_currency_id,
				tradingFees: trade.fee_amount,
				direction: trade.side === 1 ? "Sell" : "Buy",
				orderStatus: trade.order_status,
				orderQty: trade.order_qta,
				orderType: ORDER_TYPE_FLIPPED[trade.type],
				walletType: trade.wallet_type,
			}))
		},
	}))
	.actions(self => ({
		setFilteringSymbol(nextSymbol: string) {
			self.filteringSymbol = nextSymbol
		},
		setFilterTypeOpenedOrders(nextSymbol: string) {
			self.filterTypeOpenedOrders = nextSymbol
		},
		setFilterTypeOrderHistory(nextSymbol: string) {
			self.filterTypeOrderHistory = nextSymbol
		},
		setFilterTypeTradeHistory(nextSymbol: string) {
			self.filterTypeTradeHistory = nextSymbol
		},
	}))
	.actions(self => ({
		updateTrades(trade: ITradeHistory) {
			let tradeObj = trade

			if (trade.tradeFeeType !== null) {
				const pair: any = getParent<IRootStore>(self).terminal.pair
				const feeRate = tradeObj.tradeFeeType === 1 ? pair.taker_fee_rate : pair.maker_fee_rate
				const amount = tradeObj.side === 1 ? trade.amount2 : trade.amount1
				const fee_amount = feeRate * amount

				tradeObj = {
					...trade,
					fee_amount: fee_amount,
					fee_currency_id: tradeObj.side === 1 ? pair.quote_currency_code : pair.base_currency_code,
				}
			}

			self.tradeHistory.unshift(cast(tradeObj))
		},
		updateOrders(o: IOrder, onOrderOpen?: () => void, onOrderClose?: (o: IHistoryOrder) => void) {
			// TODO REFACTOR
			const order = formatHistoryOrder(o)
			if (self.filteringSymbol && o.symbol !== self.filteringSymbol) {
				return
			}

			if (isOrderDone(order)) {
				const idx = self.closedOrders.findIndex(o => o.id === order.id)
				if (idx !== -1) {
					self.closedOrders[idx] = cast({
						...self.closedOrders[idx],
						...order,
						trades: [...self.closedOrders[idx].trades],
					})
				} else {
					self.closedOrders.unshift(cast(order))
					const openedOrder = self.openedOrders
						.concat(self.triggerOrders)
						.find(o => o.id === order.id)
					if (openedOrder) {
						openedOrder.destroy()
					}
					if (onOrderClose && order.status === OrderStatusEnum.FILLED) {
						onOrderClose(order)
					}
				}
			} else if (order.type === OrderTypeEnum.STOP_LIMIT) {
				if (
					[OrderStatusEnum.OPEN, OrderStatusEnum.PARTIAL_FILLED, OrderStatusEnum.PENDING].includes(
						order.status as OrderStatusEnum,
					)
				) {
					const triggerOrder = self.triggerOrders.find(o => o.id === order.id)
					if (triggerOrder) {
						triggerOrder.destroy()
					}
					self.openedOrders.unshift(cast(order))
				} else {
					const idx = self.triggerOrders.findIndex(o => o.id === order.id)
					if (idx !== -1) {
						self.triggerOrders[idx] = cast({
							...self.triggerOrders[idx],
							...order,
						})
					} else {
						self.triggerOrders.unshift(cast(order))
					}
				}
			} else {
				const idx = self.openedOrders.findIndex(o => o.id === order.id)
				if (idx !== -1) {
					self.openedOrders[idx] = cast({
						...self.openedOrders[idx],
						...order,
						trades: [...self.openedOrders[idx].trades],
					})
				} else {
					if (onOrderOpen) {
						onOrderOpen()
					}
					self.openedOrders.unshift(cast(order))
				}
			}
		},
	}))
	.actions(self => ({
		loadOpenedOrders: flow(function* (params?: IGetOrdersParams) {
			try {
				self.isOpenedOrdersLoading = true
				const data = yield HistoryService.getOrders({ ...params, open_only: true })
				if (data) {
					if (Array.isArray(data.results)) {
						const orders = formatHistoryOrders(data.results)
						detach(self.openedOrders)

						const openedOrders = orders.filter(o =>
							[
								OrderStatusEnum.OPEN,
								OrderStatusEnum.PARTIAL_FILLED,
								OrderStatusEnum.PENDING,
							].includes(o.status as OrderStatusEnum),
						)

						self.openedOrders = cast(openedOrders)
						/* self.triggerOrders = cast(
							orders.filter(
								(o) => o.type === OrderTypeEnum.STOP_LIMIT && o.status === OrderStatusEnum.PENDING,
							),
						); */
					}
				}
			} catch (err) {
				errorHandler(err)
			} finally {
				self.isOpenedOrdersLoading = false
			}
		}),
		loadClosedOrders: flow(function* (params?: IGetOrdersParams) {
			try {
				self.isClosedOrdersLoading = true
				const data = yield HistoryService.getOrders({
					...params,
					closed_only: true,
					trades: true,
				})
				if (data) {
					if (Array.isArray(data.results)) {
						const orders = formatHistoryOrders(data.results)
						self.closedOrders = cast(orders)
					}
					self.closedOrdersCount = data.count ?? 0
				}
			} catch (err) {
				errorHandler(err)
			} finally {
				self.isClosedOrdersLoading = false
			}
		}),
		loadTradeHistory: flow(function* (params: IGetProfileTradesParams) {
			try {
				self.isTradeHistoryLoading = true
				const data = yield HistoryService.getTrades(params)

				if (data && Array.isArray(data.results)) {
					self.tradeHistory = cast(data.results)
				}
			} catch (err) {
				errorHandler(err)
			} finally {
				self.isTradeHistoryLoading = false
			}
		}),
		loadPositions: flow(function* (params: IGetPositionsParams) {
			try {
				self.isPositionsLoading = true
				const data = yield HistoryService.getPositions(params)
				if (Array.isArray(data)) {
					self.positions = cast(data)
				}
			} catch (err) {
				errorHandler(err)
			} finally {
				self.isPositionsLoading = false
			}
		}),
	}))
	.actions(self => ({
		loadDeposits: flow(function* (params: any) {
			try {
				self.isDepositsLoading = true
				const data = yield HistoryService.getDeposits(params)
				self.depositsCount = data.count

				if (Array.isArray(data.results)) {
					self.deposits = cast(data.results)
				}
			} catch (err) {
				errorHandler(err)
			} finally {
				self.isDepositsLoading = false
			}
		}),
	}))
	.actions(self => ({
		loadWithdraws: flow(function* (params: any) {
			try {
				self.isWithdrawsLoading = true
				const data = yield HistoryService.getWithdraws(params)
				self.withdrawsCount = data.count

				if (Array.isArray(data.results)) {
					self.withdraws = cast(data.results)
				}
			} catch (err) {
				errorHandler(err)
			} finally {
				self.isWithdrawsLoading = false
			}
		}),
		cancelWithdraw: flow(function* (id: number) {
			try {
				const item = yield HistoryService.cancelWithdraw(id)

				if (item.id) {
					self.withdraws = cast(
						self.withdraws.map(withdraw =>
							withdraw.id === item.id ? { ...withdraw, ...item } : withdraw,
						),
					)
				}
			} catch (err) {
				errorHandler(err)
			}
		}),
	}))

export type IHistory = Instance<typeof History>
