import React, { FC, useRef } from "react"
import Slider, { Settings } from "react-slick"

import crypto from "assets/images/home/media-partner-crypto.svg"
import walletInvestor from "assets/images/home/media-partner-wallet-investor.svg"
import digitalNotice from "assets/images/home/media-partner-digital.svg"
import coinpedia from "assets/images/home/media-partner-coinpedia.svg"
import hub from "assets/images/home/media-partner-hub.svg"
import forklog from "assets/images/home/media-partner-forklog.svg"
import infinox from "assets/images/home/infinox.svg"
import LeftControl from "assets/icons/homePage/LeftControl"
import RightControl from "assets/icons/homePage/RightControl"
import styles from "./index.module.scss"
import ExternalLink from "../../../ExternalLink"

const AUTOPLAY_SPEED = 6000
const DELAY = 300

const LIST = [
	{
		link: "https://www.cryptonewspoint.com",
		img: {
			src: crypto,
			width: "105",
			height: "38",
			alt: "crypto-news",
		},
	},
	{
		link: "https://www.infinox.com/",
		img: {
			src: infinox,
			width: "157",
			height: "26",
			alt: "infinox",
		},
	},
	{
		link: "https://walletinvestor.com",
		img: {
			src: walletInvestor,
			width: "88",
			height: "42",
			alt: "wallet-investor",
		},
	},
	{
		link: "https://www.digitalnotice.in",
		img: {
			src: digitalNotice,
			width: "92",
			height: "61",
			alt: "digital-notice",
		},
	},
	{
		link: "https://coinpedia.org",
		img: {
			src: coinpedia,
			width: "118",
			height: "20",
			alt: "coinpedia",
		},
	},
	{
		link: "https://hub.forklog.com/companies/btc-alpha",
		img: {
			src: hub,
			width: "92",
			height: "30",
			alt: "hub",
		},
	},
	{
		link: "https://forklog.com",
		img: {
			src: forklog,
			width: "124",
			height: "30",
			alt: "forklog",
		},
	},
]

const Partners: FC = () => {
	const sliderRef = useRef<Slider>(null)

	const settings: Settings = {
		dots: false,
		infinite: true,
		speed: DELAY,
		slidesToShow: 5,
		slidesToScroll: 1,
		centerMode: true,
		autoplay: true,
		autoplaySpeed: AUTOPLAY_SPEED,
		arrows: false,
		pauseOnHover: true,
		responsive: [
			{
				breakpoint: 1099,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 580,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	}

	const slideNext = () => {
		if (sliderRef.current) sliderRef.current.slickNext()
	}

	const slidePrev = () => {
		if (sliderRef.current) sliderRef.current.slickPrev()
	}

	return (
		<section className={styles.partners}>
			<h2 className="visually-hidden">Partners</h2>

			<div className={styles.partners__container}>
				<p className={styles.partners__headline}>Partners</p>

				<p className={styles.partners__content}>
					Check our awesome partners
					{/*. You can check{" "}*/}
					{/*<a className={styles.partners__contentLink} href="##">*/}
					{/*	the full list of partners*/}
					{/*</a>*/}
				</p>
			</div>

			<div className={styles.partners__slider}>
				<Slider ref={sliderRef} {...settings}>
					{LIST.map(({ link, img }) => (
						<div key={`${link}-partner`}>
							<ExternalLink to={link} className={styles.partners__sliderItem}>
								<img
									src={img.src}
									width={img.width}
									height={img.height}
									alt={img.alt}
									loading="lazy"
								/>
							</ExternalLink>
						</div>
					))}
				</Slider>
			</div>

			<div className={styles.partners__sliderControls}>
				<button
					onClick={slidePrev}
					type="button"
					aria-label="slide left"
					className={styles.partners__leftControl}
				>
					<LeftControl />
				</button>

				<button
					type="button"
					onClick={slideNext}
					aria-label="slide right"
					className={styles.partners__rightControl}
				>
					<RightControl />
				</button>
			</div>
		</section>
	)
}

export default Partners
