import React, { FC } from "react"

import ExternalLink from "components/ExternalLink"
import { links } from "constants/links"
import TradingVolumeImage from "assets/images/HomePage/TradingVolumeImage.webp"
import styles from "./index.module.scss"

const TradingVolume: FC = () => (
	<div className={styles.tradingVolume}>
		<div className={styles.tradingVolume__content}>
			<div className={styles.tradingVolume__contentWrapper}>
				<h3 className={styles.tradingVolume__headline}>Trading Volume</h3>

				<p className={styles.tradingVolume__contentText}>
					Our trading volume is, on average, 200 million Dollars per day. This is all because of our
					strong customer base and their belief in our platform. You can see this via our{" "}
					<ExternalLink
						className={`${styles.mobileApp__button} ${styles.mobileApp__button___disabled}`}
						to={links.coinMarketCapUrl}
					>
						CoinMarketCap
					</ExternalLink>{" "}
					or{" "}
					<ExternalLink
						className={`${styles.mobileApp__button} ${styles.mobileApp__button___disabled}`}
						to={links.coinGeckoUrl}
					>
						CoinGecko
					</ExternalLink>{" "}
					memberships.
				</p>
			</div>
		</div>

		<img
			className={styles.tradingVolume__svg}
			src={TradingVolumeImage}
			width="280"
			height="280"
			alt="Trading Volume"
		/>
	</div>
)

export default TradingVolume
