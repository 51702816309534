import React, { useRef, useState, useMemo, useCallback } from "react"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"

import TooltipPortal from "components/NewUI/TooltipPortal"
import styles from "../../index.module.scss"

const Item: React.FC<{
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	item: any
	maxDepth: number
	amount_precision: number
	decimalPlaces: number
	isSell: boolean
	quote_currency_code: string | undefined
	base_currency_code: string | undefined
	handleOrderClick: (item: any) => void
}> = ({
	item,
	maxDepth,
	amount_precision,
	decimalPlaces,
	isSell,
	quote_currency_code,
	base_currency_code,
	handleOrderClick,
}) => {
	const { formatNumber } = useIntl()
	const [tooltipContent, setTooltipContent] = useState<boolean>(false)
	const [position, setPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 })
	const itemRef = useRef<HTMLLIElement | null>(null)
	const percent = useMemo(() => (item.amount / maxDepth) * 100 * 1.8, [maxDepth, item.amount])
	const amount2Depth = useMemo(
		() =>
			formatNumber(item.amount2Accum, {
				maximumFractionDigits: decimalPlaces,
				minimumFractionDigits: decimalPlaces,
			}),
		[item.amount2Accum, decimalPlaces],
	)
	const amountDepth = useMemo(
		() =>
			formatNumber(item.amountAccum, {
				maximumFractionDigits: amount_precision,
				minimumFractionDigits: amount_precision,
			}),
		[item.amountAccum, amount_precision],
	)
	// const amount2 = useMemo(
	// 	() =>
	// 		formatNumber(item.amount2, {
	// 			maximumFractionDigits: decimalPlaces,
	// 			minimumFractionDigits: decimalPlaces,
	// 		}),
	// 	[item.amount2, decimalPlaces],
	// );
	const amount = useMemo(
		() =>
			formatNumber(item.amount, {
				maximumFractionDigits: amount_precision,
				minimumFractionDigits: amount_precision,
			}),
		[item.amount, amount_precision],
	)
	const price = useMemo(
		() =>
			formatNumber(item.price, {
				maximumFractionDigits: decimalPlaces,
				minimumFractionDigits: decimalPlaces,
			}),
		[item.price, decimalPlaces],
	)

	const avgPrice = useMemo(() => {
		const num = item.amount2Accum / item.amountAccum

		return formatNumber(num, {
			maximumFractionDigits: decimalPlaces,
			minimumFractionDigits: decimalPlaces,
		})
	}, [item.amount2Accum, item.amountAccum, decimalPlaces])

	const handleMouseEnter = () => {
		if (itemRef.current) {
			const rect = itemRef.current.getBoundingClientRect()
			setTooltipContent(true)
			setPosition({
				top: rect.bottom,
				left: rect.left,
			})
		}
	}

	const handleMouseLeave = () => {
		setTooltipContent(false)
	}

	const onClick = useCallback(() => handleOrderClick(item), [item])

	return (
		<>
			<li
				ref={itemRef}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				className={styles.orders__listItem}
			>
				<button type="button" onClick={onClick} className={styles.orders__listButton}>
					<span
						className={styles.orders__itemIndicator}
						style={{ transform: `scaleX(${percent})` }}
					/>

					<span
						className={`${styles.orders__itemUserIndicator} ${
							item.isUserOrder ? styles.orders__itemUserIndicator___active : ""
						}`}
					/>

					<p className={styles.orders__itemPrice}>{price}</p>

					<p className={styles.orders__itemQty}>{amount}</p>

					<p className={styles.orders__itemTotal}>{amountDepth}</p>
				</button>
			</li>

			{tooltipContent ? (
				<TooltipPortal>
					<div
						className={`${styles.orders__itemTooltip} ${
							!isSell ? styles.orders__itemTooltip___green : ""
						}`}
						style={{
							top: isSell ? position.top - 25 : position.top,
							left: position.left + 307,
							zIndex: 1000,
						}}
					>
						<div className={styles.orders__itemTooltipContent}>
							<p className={styles.orders__itemTooltipText}>
								<span>Avg price ({quote_currency_code && quote_currency_code}):</span>

								<span className={styles.orders__itemTooltipTextRight}>{avgPrice}</span>
							</p>

							<p className={styles.orders__itemTooltipText}>
								<span>Sum ({base_currency_code && base_currency_code}):</span>

								<span className={styles.orders__itemTooltipTextRight}>{amountDepth}</span>
							</p>

							<p className={styles.orders__itemTooltipText}>
								<span>Sum ({quote_currency_code && quote_currency_code}):</span>

								<span className={styles.orders__itemTooltipTextRight}>{amount2Depth}</span>
							</p>
						</div>
					</div>
				</TooltipPortal>
			) : null}
		</>
	)
}

export default observer(Item)
