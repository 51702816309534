import React, { FC } from "react"

import InternalLink from "components/InternalLink"
import { routes } from "constants/routing"
import { useMst } from "models/Root"
import Support247Image from "assets/images/HomePage/Support247Image.webp"
import styles from "./index.module.scss"

const Support247: FC = () => {
	const {
		render: { supportCenter },
	} = useMst()

	return (
		<div className={styles.support}>
			<div className={styles.support__content}>
				<div className={styles.support__contentWrapper}>
					<h3 className={styles.support__headline}>VIP 24/7 support</h3>

					<p className={styles.support__contentText}>
						From the moment you make first crypto transaction to your first NFT purchase, we will be
						with you throughout the entire process. Our support team is always available and will
						always speak to you in person - no more speaking to a bot!
					</p>
				</div>

				{supportCenter ? (
					<InternalLink to={routes.support.root}>
						<button className={styles.support__button} type="button">
							Learn more
						</button>
					</InternalLink>
				) : null}
			</div>

			<img
				className={styles.support__svg}
				src={Support247Image}
				width="280"
				height="280"
				alt="Support 24/7"
			/>
		</div>
	)
}

export default Support247
